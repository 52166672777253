<script>
import api from '@/command/api'
import moment from 'moment'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { Button, DatePicker, Modal } from 'ant-design-vue'
import apiTool from '@/command/apiTool'
import { deleteAction, getAction, postAction } from '@/command/netTool'
import { getAllDates } from '@/utils/dateHandle'
export default {
  name: 'calendar',
  data() {
    return {
      ...api.command.getState(),
      daytime: moment(new Date()).format('YYYY-MM-DD'),
      batchSetData: {},
      dateAdds: [
        {
          date_g: '2022-09-14',
          week: '周三'
        }
      ],
      weekList: [
        {
          name: '星期一',
          value: '1'
        },
        {
          name: '星期二',
          value: '2'
        },
        {
          name: '星期三',
          value: '3'
        },
        {
          name: '星期四',
          value: '4'
        },
        {
          name: '星期五',
          value: '5'
        },
        {
          name: '星期六',
          value: '6'
        },
        {
          name: '星期日',
          value: '7'
        }
      ],
      dayList: [],
      productId: '',
      productType: '',
      thirdTicket: '',
      dateArr: []
    }
  },
  watch: {
    daytime(nVal, oVal) {
      this.getInit()
    }
  },
  mounted() {
    const { productId, productType, thirdTicket } = this.$route.query
    this.productId = productId
    this.productType = productType
    this.thirdTicket = thirdTicket

    this.getInit()
    for (let i = 1; i <= moment(new Date()).daysInMonth(); i++) {
      this.dayList.push({
        name: i,
        value: `${i}`
      })
    }
  },
  methods: {
    getWeek(dateArr) {
      if (!dateArr.length) return
      let arr = [...new Set(dateArr.map(e => moment(e).day()))]
      this.weekList = this.weekList.map(e => {
        if (e.value == '7') {
          return {
            ...e,
            disabled: !arr.includes(0)
          }
        } else {
          return {
            ...e,
            disabled: !arr.includes(Number(e.value))
          }
        }
      })
    },
    getWeek2(dateArr) {
      if (!dateArr.length) return
      let arr = [...new Set(dateArr.map(e => moment(e).format('D')))]
      this.dayList = this.dayList.map(e => {
        return {
          ...e,
          disabled: !arr.includes(e.value)
        }
      })
    },
    getInit() {
      getAction(
        '/goods/farmTicketPriceConfig/queryCalendarList',
        {
          productId: this.productId,
          productType: this.productType,
          date: this.daytime
        },
        '/api'
      ).then(({ data }) => {
        const { titleList, dataList } = data
        this.dateAdds = titleList.map(e => {
          return {
            date_g: e.title,
            week: e.weekDesc
          }
        })
        this.records = dataList
      })
    },
    getColumns() {
      let arr = [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left'
        },
        {
          dataIndex: 'productName',
          title: '名称',
          align: 'center'
        }
      ]
      this.dateAdds.forEach((e, indexId) => {
        arr.push({
          align: 'center',
          title: () => {
            return (
              <div>
                <div>{e.date_g}</div>
                <div>{e.week}</div>
              </div>
            )
          },
          customRender: (text, records, index) => {
            let is_show = this.thirdTicket == '1' && !records['list'][indexId]['salePrice']
            return (
              <div
                class={`product-order-item-copy ${is_show ? 'no' : 'yes'}`}
                onClick={() => !is_show && this.itemClick(e, records)}
              >
                <div v-show={records['list'][indexId]['salePrice']}>价格：{records['list'][indexId]['salePrice']}</div>
              </div>
            )
          }
        })
      })
      return arr
    },
    itemClick(e, records) {
      let { date_g } = e
      let { list, productName } = records
      let obj = list.find(f => f.date === date_g) || {
        originalPrice: 0,
        salePrice: 0,
        jsPrice: 0,
        allStock: 0
      }
      let { originalPrice, salePrice, jsPrice, allStock } = obj
      apiTool.showModal({
        width: '600px',
        title: '日历价格设置',
        success: ({ data, setHidden }) => {
          let obj = Object.assign({}, data, { productId: records.productId, productType: this.productType })
          postAction('/goods/farmTicketPriceConfig/saveByDate', obj).then(res => {
            if (res.code == 200) {
              this.getInit()
              this.$message.success('操作成功')
              setHidden()
            } else {
              this.$message.error('操作失败')
            }
          })
        },
        formData: {
          name: productName,
          date: date_g,
          originalPrice,
          salePrice,
          jsPrice,
          allStock
        },
        form: [
          {
            name: '商品名',
            type: 'text',
            key: 'name',
            labelCol: { span: 5 },
            wrapperCol: { span: 19 }
          },
          {
            name: '日期',
            type: 'text',
            key: 'date',
            labelCol: { span: 5 },
            wrapperCol: { span: 19 }
          },
          {
            name: '原价',
            type: 'inputNumber',
            key: 'originalPrice',
            labelCol: { span: 5 },
            wrapperCol: { span: 19 },
            rules: [{ required: true, type: 'number' }],
            props: {
              min: 0
            }
          },
          {
            name: '销售价',
            type: 'inputNumber',
            key: 'salePrice',
            labelCol: { span: 5 },
            wrapperCol: { span: 19 },
            rules: [{ required: true, type: 'number' }],
            props: {
              min: 0
            }
          },
          {
            name: '商家结算价',
            type: 'inputNumber',
            key: 'jsPrice',
            labelCol: { span: 5 },
            wrapperCol: { span: 19 },
            rules: [{ required: true, type: 'number' }],
            props: {
              min: 0
            }
          },
          {
            name: '库存',
            type: 'inputNumber',
            key: 'allStock',
            disabled: this.thirdTicket == '1',
            labelCol: { span: 5 },
            wrapperCol: { span: 19 },
            rules: [{ required: true, type: 'number' }],
            props: {
              min: 0,
              precision: 0
            }
          }
        ]
      })
    },
    tenDaysAgo() {
      this.daytime = apiTool.recentTime(-10, 'yyyy-MM-dd', this.daytime)
    },
    tenDaysLaters() {
      this.daytime = apiTool.recentTime(10, 'yyyy-MM-dd', this.daytime)
    },
    onChange(time) {
      this.daytime = moment(time).format('YYYY-MM-DD')
    },
    showConfirm() {
      Modal.confirm({
        title: '提示',
        content: '此操作将删除该商品所有价格, 是否继续？',
        onOk: () => {
          let productId = this.records.map(e => e.productId).toString()
          deleteAction(
            '/goods/farmTicketPriceConfig/clearALL?productId=' + productId + '&productType=' + this.productType,
            {},
            '/api'
          ).then(res => {
            if (res.code == 200) {
              this.getInit()
              this.$message.success('操作成功')
            } else {
              this.$message.error('操作失败')
            }
          })
        },
        onCancel() {}
      })
    },
    getHeaderRender() {
      return (
        <div class="header-button">
          <span>
            <Button type="primary" onClick={this.batchSet} disabled={this.thirdTicket == '1'}>
              批量设置
            </Button>
            <Button style={{ marginLeft: '12px' }} type="default" onClick={this.showConfirm}>
              清除报价
            </Button>
          </span>
          <span>
            <Button type="default" onClick={this.tenDaysAgo}>
              ＜ 前10天
            </Button>
            <DatePicker
              allowClear={false}
              onChange={this.onChange}
              style={{ width: '100%', marginLeft: '12px' }}
              value={this.daytime}
            ></DatePicker>
            <Button style={{ marginLeft: '12px' }} type="default" onClick={this.tenDaysLaters}>
              后10天 ＞
            </Button>
          </span>
        </div>
      )
    },
    getForm(formInfo) {
      let that = this
      return [
        {
          name: '日期',
          type: 'rangePicker',
          keys: ['startDate', 'endDate'],
          ranges: {
            最近一周: [moment(), moment().add(1, 'weeks')],
            最近一个月: [moment(), moment().add(1, 'month')],
            最近三个月: [moment(), moment().add(3, 'month')]
          },
          labelCol: { span: 4 },
          wrapperCol: { span: 15 },
          onChange: first => {
            if (first.length) {
              let startTime = moment(first[0]).format('YYYY-MM-DD')
              let endTime = moment(first[1]).format('YYYY-MM-DD')
              this.dateArr = getAllDates(startTime, endTime)
              this.getWeek(this.dateArr)
              this.getWeek2(this.dateArr)
            } else {
              this.dateArr = []
            }
          }
        },
        {
          name: '日期方式',
          type: 'radioButton',
          key: 'dateType',
          labelCol: { span: 4 },
          wrapperCol: { span: 19 },
          typeData: [
            {
              name: '全部',
              value: '0'
            },
            {
              name: '指定星期',
              value: '1'
            },
            {
              name: '指定日期',
              value: '2'
            }
          ],
          onChange: () => {
            formInfo.dateValue = []
          }
        },
        ...[
          {
            name: '选择星期',
            type: 'checkBoxButton',
            key: 'dateValue',
            labelCol: { span: 4 },
            wrapperCol: { span: 19 },
            typeData: this.weekList,
            display: formInfo.dateType == '1'
          },
          {
            name: '选择日期',
            type: 'checkBoxButton',
            key: 'dateValue',
            labelCol: { span: 4 },
            wrapperCol: { span: 19 },
            typeData: this.dayList,
            display: formInfo.dateType == '2',
            styles: {
              width: '80px'
            }
          }
        ].filter(e => e.display),
        {
          type: 'table',
          showFootButton: false,
          showRowSelect: false,
          showPagination: false,
          labelCol: { span: 0 },
          wrapperCol: { span: 24 },
          columns: [
            {
              dataIndex: 'name',
              align: 'left',
              width: '20%',
              title: '名称',
              customRender: function(text, records) {
                return <a-input disabled={true} placeholder="名称" value={text} />
              }
            },
            {
              dataIndex: 'originalPrice',
              align: 'left',
              width: '20%',
              title: '原价',
              customRender: function(text, records) {
                const onInput = data => {
                  if (data.target.value) {
                    if (!/[0-9\.]/.test(data.target.value)) {
                      data.target.value = null
                      that.$message.warning('请输入数字！')
                      return false
                    } else if (data.target.value < 0) {
                      that.$message.warning('请输入正数！')
                      data.target.value = null
                      return false
                    } else {
                      records.originalPrice = data.target.value
                    }
                  } else {
                    records.originalPrice = ''
                  }
                }
                return (
                  <a-input type="number" placeholder="原价" addon-before="￥" onChange={onInput} value={text} min={0} />
                )
              }
            },
            {
              dataIndex: 'salePrice',
              align: 'left',
              width: '20%',
              title: '销售价',
              customRender: function(text, records) {
                const onInput = data => {
                  if (data.target.value) {
                    if (!/[0-9\.]/.test(data.target.value)) {
                      data.target.value = null
                      that.$message.warning('请输入数字！')
                      return false
                    } else if (data.target.value < 0) {
                      that.$message.warning('请输入正数！')
                      data.target.value = null
                      return false
                    } else {
                      records.salePrice = data.target.value
                    }
                  } else {
                    records.salePrice = ''
                  }
                }
                return <a-input type="number" placeholder="销售价" addon-before="￥" onInput={onInput} value={text} />
              }
            },
            {
              dataIndex: 'jsPrice',
              align: 'left',
              width: '20%',
              title: '商家结算价',
              customRender: function(text, records) {
                const onInput = data => {
                  if (data.target.value) {
                    if (!/[0-9\.]/.test(data.target.value)) {
                      data.target.value = null
                      that.$message.warning('请输入数字！')
                      return false
                    } else if (data.target.value < 0) {
                      that.$message.warning('请输入正数！')
                      data.target.value = null
                      return false
                    } else {
                      records.jsPrice = data.target.value
                    }
                  } else {
                    records.jsPrice = ''
                  }
                }
                return (
                  <a-input type="number" placeholder="商家结算价" addon-before="￥" onChange={onInput} value={text} />
                )
              }
            },
            {
              dataIndex: 'allStock',
              align: 'left',
              width: '20%',
              title: '库存',
              customRender: function(text, records) {
                const onInput = data => {
                  if (data.target.value) {
                    if (!/^\+?[1-9]\d*$/.test(data.target.value)) {
                      data.target.value = null
                      that.$message.warning('请输入正整数！')
                      return false
                    }
                    //  else if (data.target.value < 0) {
                    //   that.$message.warning('请输入正数！')
                    //   data.target.value = null
                    //   return false
                    // }
                    else {
                      records.allStock = data.target.value
                    }
                  } else {
                    records.allStock = ''
                  }
                }
                return <a-input type="number" placeholder="库存" onChange={onInput} value={text} />
              }
            }
          ],
          dataSource: formInfo.batchSetData
        }
      ]
    },
    batchSet() {
      let dataArr = this.$refs.tableExternal.$refs.table.selectedRowKeys
      if (dataArr.length == 0) {
        this.$message.error('至少勾选一个商品')
      } else {
        let productName = this.records.find(f => f.id == dataArr[0])?.productName || this.records[0]['productName']
        let productId = this.records.find(f => f.id == dataArr[0])?.productId || this.records[0]['productId']

        let formData = {
          startDate: '',
          endDate: '',
          dateType: '',
          dateValue: '',
          batchSetData: [
            {
              name: productName,
              originalPrice: '',
              salePrice: '',
              jsPrice: '',
              allStock: ''
            }
          ]
        }
        apiTool.showModal({
          width: '800px',
          title: '日历价格设置',
          formData,
          form: () => this.getForm(formData),
          success: ({ data, setHidden }) => {
            let params = {
              ...data,
              ...data.batchSetData[0],
              dateValue: data.dateValue.toString(),
              productId,
              productType: this.productType
            }
            this.$delete(params, 'batchSetData')
            console.log(params, '批量设置')
            postAction('/goods/farmTicketPriceConfig/saveBatch', params).then(res => {
              if (res.code == 200) {
                this.getInit()
                this.$message.success('操作成功')
                this.$refs.tableExternal.$refs.table.selectedRowKeys = []
                setHidden()
              } else {
                this.$message.error('操作失败')
              }
            })
          }
        })
      }
    }
  },
  render() {
    return (
      <div class="body-card">
        {this.getHeaderRender()}
        <TemplateHeaderTable
          ref="tableExternal"
          tableColumns={this.getColumns()}
          records={this.records}
          filterRightButtonKey={[]}
          showPagination={true}
          bordered={true}
        />
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
/deep/.ant-card-body {
  padding-top: 4px;
}
.body-card {
  border: 1px solid rgba(232, 234, 236, 1);
  border-radius: 4px;
  min-height: calc(100vh - 128px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
}
/deep/ .template-card {
  border: 0;
  min-height: calc(100vh - 200px);
}
/deep/.ant-btn {
  border-radius: 5px;
}
.header-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  padding: 24px 24px 0 24px;
  i {
    cursor: pointer !important;
  }
  span {
    display: flex;
    align-items: center;
  }
}
.product-order-item-copy {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  height: 55px;

  &.yes {
    cursor: pointer;

    &:hover {
      background-color: #e5e5e5;
    }
  }

  &.no {
    cursor: not-allowed;
  }
}
/deep/
  .ant-table-middle
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr
  > td {
  padding: 0;
}
</style>
